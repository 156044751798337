import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import Custom Components
import MainMenu from './partials/main-menu';
import CartMenu from './partials/cart-menu';
import CategoryMenu from './partials/category-menu';
import LoginModal from '../features/modal/login-modal.tsx';

import { showModal, logout } from '../../actions';
import { stickyHeaderHandler, preventHeaderDefault } from '../../utils';

function Header(props) {
    const { container = "container", user } = props;

    useEffect(() => {
        window.addEventListener('scroll', stickyHeaderHandler, true);
        preventHeaderDefault();

        return () => {
            window.removeEventListener('scroll', stickyHeaderHandler);
        }
    }, [])


    useEffect(() => {

    }, [user])

    function openLoginModal(e) {
        props.showModal('login');
        e.preventDefault();
    }
    function handleLogout() {
        props.logout();
    }

    const onChangeHandler = (e) => {
        const value = e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value,
        })
    }

    const [formData, setFormData] = useState({
        q: '',
        cat: '',
    });




    return (
        <header className="header header-12">
            <div className="header-top">
                <div className={container}>
                    <div className="header-left">
                        <Link to="tel:#"><i className="icon-phone"></i>Call: +233 59 381 1088</Link>
                    </div>

                    <div className="header-right">
                        <ul className="top-menu">
                            <li>
                                <Link to="#">Links</Link>
                                <ul className="dropdown-links">
                                    <li>
                                        <div className="header-dropdown">
                                            <Link to="#">GHS</Link>
                                            <div className="header-menu">
                                                <ul>
                                                    {/* <li><Link to="#">GHS</Link></li> */}
                                                    {/* <li><Link to="#">Usd</Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="header-dropdown">
                                            <Link to="#">English</Link>
                                            <div className="header-menu">
                                                <ul>
                                                    {/* <li><Link to="#">English</Link></li>
                                                    <li><Link to="#">French</Link></li>
                                                    <li><Link to="#">Spanish</Link></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="login">
                                        {(!user.user.access_token) && <Link to="#signin-modal" data-toggle="modal" onClick={openLoginModal}>Sign in</Link>}
                                        {(user && user.user.access_token) && <Link to="#" onClick={handleLogout}>Logout</Link>}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="header-middle">
                <div className={container}>
                    <div className="header-left">
                        <button className="mobile-menu-toggler">
                            <span className="sr-only">Toggle mobile menu</span>
                            <i className="icon-bars"></i>
                        </button>

                        <Link to={`${process.env.PUBLIC_URL}/`} className="logo">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="JayKay Global Logo" width="200" />
                        </Link>
                    </div>

                    <div className="header-right">
                        <div className="header-search header-search-extended header-search-visible header-search-no-radius">
                            <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>
                            <form action="/product/search" method="get">
                                <div className="header-search-wrapper search-wrapper-wide">
                                    <label htmlFor="q" className="sr-only">Search</label>
                                    <input type="search" className="form-control" name="q" id="q" placeholder="Search product ..." value={formData.q} required onChange={onChangeHandler} />
                                    <div className="select-custom">
                                        <select id="cat" name="cat" onChange={onChangeHandler}>
                                            <option value="">All Categories</option>
                                            <option value="1">Digital Printing</option>
                                            <option value="2">Large Format Printing</option>
                                            <option value="3">Plate making</option>
                                            <option value="4"> others</option>

                                        </select>
                                    </div>
                                    <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                                </div>
                            </form>
                        </div>
                        <div className="header-dropdown-link">
                            {(user && user.user.access_token) && <div className="account">
                                <Link to={`${process.env.PUBLIC_URL}/shop/dashboard`} title="My account">
                                    <div className="icon">
                                        <i className="icon-user"></i>
                                    </div>
                                    <p>Account</p>
                                </Link>
                            </div>}

                            <div className="wishlist">
                                <Link to={`${process.env.PUBLIC_URL}/shop/wishlist`} title="Wishlist">
                                    <div className="icon">
                                        <i className="icon-heart-o"></i>
                                        <span className="wishlist-count">{props.wishlist.length}</span>
                                    </div>
                                    <p>Wishlist</p>
                                </Link>
                            </div>

                            <CartMenu />
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-bottom sticky-header">
                <div className={container}>
                    <div style={{ backgroundColor: '#333', display: 'flex', width: '100%' }}>
                        <div className="header-left">
                            <CategoryMenu />
                        </div>

                        <div className="header-center">
                            <MainMenu />
                        </div>

                        <div className="header-right">
                            <i className="la la-lightbulb-o"></i><p>Up to 20% Off Your first order</p>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal />
        </header>
    )
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.list,
        user: state.user
    }
}

export default connect(mapStateToProps, { showModal, logout })(Header);