import React, { useEffect, useState, useCallback, useRef } from 'react';

import Card from './card';
import { Link } from 'react-router-dom';

import { quantityInputs, isIEBrowser, isEdgeBrowser, findIndex, countTo } from '../../../utils';
import WasabiUploader from '../../common/wasabi-uploader';
import { toast } from 'react-toastify';



function CartActionTwo(props) {


    const { addToCart, product, varia, vcount, selectedBaseline } = props;



    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState('...')
    const [control, setControl] = useState(true)
    const [formData, setFormData] = useState([])
    const [unitData, setUnitData] = useState({
        l: 1,
        h: 1,
        lunit: "F",
        hunit: "F",
    })


    const onChangeHandler = (e) => {
        const value = e.target.value;

        setUnitData({
            ...unitData,
            [e.target.name]: value,
        })


    }

    const sqm = useRef(1.00);

    const calculate = () => {
        let tl = 0
        let th = 0
        const { lunit, hunit, l, h } = unitData;
        if (lunit == "F") tl = l
        if (hunit == "F") th = h
        if (lunit == "CM") tl = l / 30.48
        if (hunit == "CM") th = h / 30.48
        if (lunit == "I") tl = l / 12
        if (hunit == "I") th = h / 12
        if (lunit == "M") tl = l * 3.281
        if (hunit == "M") th = h * 3.281
        sqm.current = parseFloat(tl) * parseFloat(th);
        return parseFloat(tl) * parseFloat(th)

    }



    useEffect(() => {
        updatedPrice()
    }, [control, unitData.l, unitData.h, unitData.lunit, unitData.hunit]);



    const onFileUploadCompleted = useCallback((sentFile) => {
        if (undefined !== sentFile.Location) {
            setFormData(initial => {
                let found = false
                const files = initial.map((f) => {
                    if (f.key === sentFile.key) {
                        f = sentFile
                        found = true
                    }
                    return f
                })
                if (!found)
                    return [...initial, sentFile]
                else return files
            })
        }
        setControl(!control);
    })
    const onFileDeleted = useCallback((key) => {
        if (undefined !== key) {
            setFormData((initial) => initial.filter((i) => i.path !== key))
        }
        setControl(!control);
    })


    const updatedPrice = () => {
        let qty = document.querySelector('#qty' + vcount).value;
        let basicPrice = document.querySelector('#basicPrice' + vcount).value;
        setQuantity(qty)
        let cost = sqm.current * basicPrice * qty
        setPrice(cost);

    }

    const addToCartHandler = (v, variation) => {

        if (formData && formData.length > 0) {
            let dyn = product.baseline.filter((x) => x.isDynamic == 1);
            if (dyn.length > 0) {
                varia[dyn[0].id].name = sqm.current.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }
            let productToCart = {
                ...product,
                price: parseFloat(price.toLocaleString(undefined, { minimumFractionDigits: 2 })),
                variation: varia,
                qty: parseInt(quantity),
                assets: formData.length > 0 ? formData : []
            }
            addToCart(productToCart, quantity);
        }
        else
            toast.success("Please upload your design to continue")
    }


    let options = (minQty) => {
        let options = []
        for (let i = 1; i <= 100; i++) {
            options.push(<option value={i * minQty}>{i * minQty}</option>);
        }
        return options
    }


    const titleTransform = (v) => {
        let xx = {}
        const b = Object.fromEntries(
            Object.entries(selectedBaseline).filter(([key, value]) => {
                if (key == v) xx = value
                return key == v
            }))
        return xx.baselineName;
    }


    const handleQtyChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setControl(!control);
    }






    return (
        <Card key={vcount} title={varia.name + " - GHS " + price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} expanded={vcount == 1 && true} adClass="card-box card-sm">

            <div className="row">
                <div className="col-sm-3">
                    <label>Length Unit *</label>
                    <select className="form-control" required onChange={onChangeHandler} value={unitData.lunit} name="lunit" >
                        <option value="F">Feet</option>
                        <option value="I">Inches</option>
                        <option value="M">Meters</option>
                        <option value="CM">Centimeters</option>
                    </select>
                </div>

                <div className="col-sm-5">
                    <label>Length *</label>
                    <input type="number" className="form-control" required onChange={onChangeHandler} value={unitData.l} name="l" />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-3">
                    <label>Height Unit *</label>
                    <select className="form-control" required onChange={onChangeHandler} value={unitData.hunit} name="hunit" >
                        <option value="F">Feet</option>
                        <option value="I">Inches</option>
                        <option value="M">Meters</option>
                        <option value="CM">Centimeters</option>
                    </select>
                </div>

                <div className="col-sm-5">
                    <label>Height *</label>
                    <input type="number" className="form-control" required onChange={onChangeHandler} value={unitData.h} name="h" />
                </div>
            </div>


            <div className="product-desc-content">
                {Object.keys(varia).map(key => {
                    if (key != 'qty' && key != 'name' && key != 'price' && titleTransform(key) != 'Sq Feet')
                        return <div className="row mb-1"><div className="col-4"><strong>{titleTransform(key)}:</strong></div> <div className="col-4">{varia[key].name}</div></div>
                    if (titleTransform(key) == 'Sq Feet')
                        return <div className="row mb-1"><div className="col-4"><strong>Sq Feet:</strong></div> <div className="col-4">{calculate().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div></div>


                })
                }                        </div>

            <WasabiUploader maxFiles={5} onUploadCompleted={onFileUploadCompleted} onFileDeleted={onFileDeleted} />

            <div className="product-details-action mt-3">
                <div className="details-filter-row details-row-size">
                    <div className="product-details-quantity mt-1 mr-3">
                        <input type="hidden" id={"basicQty" + vcount} value={varia.qty} />
                        <input type="hidden" id={"basicPrice" + vcount} value={varia.price} />
                        <select className="form-control" id={'qty' + vcount} onChange={() => updatedPrice()}>
                            {options(varia.qty)}
                        </select>
                    </div>
                </div>

                {isIEBrowser() || isEdgeBrowser() ?
                    <button className="btn-product btn-cart" onClick={() => addToCartHandler(varia)} style={{ minHeight: "4rem" }}><span>add to cart</span></button>
                    :
                    <button className="btn-product btn-cart" onClick={() => addToCartHandler(varia)}><span>add to cart</span></button>
                }

            </div>

        </Card>
    );
}

export default React.memo(CartActionTwo);