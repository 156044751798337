import React from 'react';
import { Link } from 'react-router-dom';

function FooterFive(props) {
    const { container = "container", logo = "assets/images/logo-footer.png" } = props;

    return (
        <footer className="footer footer-2">
            <div className="footer-middle border-0">
                <div className={container}>
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 col-xl-5">
                            <div className="widget widget-about">
                                <img src={process.env.PUBLIC_URL + '/' + logo} className="footer-logo" alt="Footer Logo" width="105" height="25" />
                                <p>Over 20 years, Jay Kay Industries & Investments Ltd. has been committed to provide excellent product and services to Paper & Printing Industry across Ghana and West Africa. Our team achieves this by sustaining appropriate balance between demand and supply, even in the highest pressure and time sensitive situations. </p>


                                <div className="widget-about-info">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4">
                                            <span className="widget-about-title">Got Question? Call us 24/7</span>
                                            <Link to="tel:123456789">+233 59 381 1088</Link>
                                        </div>
                                        <div className="col-sm-6 col-md-8">
                                            <span className="widget-about-title">Payment Method</span>
                                            <figure className="footer-payments">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="Payment methods" width="272" height="20" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-7">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="widget">
                                        <h4 className="widget-title">Information</h4>

                                        <ul className="widget-list">
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/about`}>About JayKay</Link></li> */}
                                            <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`}>FAQ</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact us</Link></li>
                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/pages/login`}>Log in</Link></li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="widget">
                                        <h4 className="widget-title">Customer Service</h4>

                                        <ul className="widget-list">
                                            <li><Link to="/pages/return-policy">Return and Refund Policy</Link></li>
                                            <li><Link to="/pages/terms-and-conditions">Terms and conditions</Link></li>
                                            <li><Link to="/pages/privacy-policy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="widget">
                                        <h4 className="widget-title">My Account</h4>

                                        <ul className="widget-list">
                                            <li><Link to="#">Sign In</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/cart`}>View Cart</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/shop/wishlist`}>My Wishlist</Link></li>
                                            {/* <li><Link to="#">Track My Order</Link></li>
                                            <li><Link to="#">Help</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className={container}>



                    <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} JayKay Store. All Rights Reserved.</p>
                    <ul className="footer-menu">
                        <li><Link to="/pages/terms">Terms Of Use</Link></li>
                        <li><Link to="/pages/privacy-policy">Privacy Policy</Link></li>
                    </ul>

                    <a
                        href="https://wa.me/233593811088"
                        className="whatsapp_float"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="icon-whatsapp"></i>
                    </a>

                    <div className="social-icons social-icons-color">
                        <span className="social-label">Social Media</span>
                        <a href="https://www.facebook.com/jaykayghana" className="social-icon social-facebook" rel="noopener noreferrer" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                        <a href="https://twitter.com/jaykayghana" className="social-icon social-twitter" rel="noopener noreferrer" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                        <a href="https://www.instagram.com/jaykayghana/" className="social-icon social-instagram" rel="noopener noreferrer" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                        <a href="https://youtube.com" className="social-icon social-youtube" rel="noopener noreferrer" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                        <a href="https://www.linkedin.com/company/jaykay-groups/" className="social-icon social-linkedin" rel="noopener noreferrer" title="LinkedIn" target="_blank"><i className="icon-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default React.memo(FooterFive);