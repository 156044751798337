import React, { useEffect } from 'react';

import { SlideToggle } from 'react-slide-toggle';
import { safeContent } from '../../../utils';

function Card(props) {
    const { title, expanded, adClass, type = "default", clickHandler = false } = props;

    let actions = (clickHandle, toggle, isExpanded) => {
        toggle()
        if (clickHandle && isExpanded !== 'expanded') {
            clickHandle()
        }
    }

    return (
        "default" === type ?

            <SlideToggle
                collapsed={expanded ? false : true} >
                {({ onToggle, setCollapsibleElement, toggleState }) => (
                    <div className={`card ${adClass}`}>
                        <div className="card-header" onClick={() => actions(clickHandler, onToggle, toggleState.toLowerCase())} style={{ height: 'auto' }}>
                            <h2 className="card-title">
                                <span className={`toggle-button ${toggleState.toLowerCase()}`} dangerouslySetInnerHTML={safeContent(title)} style={{ height: 'auto' }}></span>
                            </h2>
                        </div>
                        <div ref={setCollapsibleElement}>
                            <div className="card-body">
                                {props.children}
                            </div>
                        </div>
                    </div>
                )
                }
            </SlideToggle> :

            <SlideToggle
                collapsed={expanded ? false : true} >
                {({ onToggle, setCollapsibleElement, toggleState }) => (
                    <div className={`acc-item ${adClass}`}>
                        <h5>
                            <span className={`toggle-button ${toggleState.toLowerCase()}`} dangerouslySetInnerHTML={safeContent(title)} onClick={() => actions(clickHandler, onToggle, toggleState.toLowerCase())} style={{ height: 'auto' }}></span>
                        </h5>
                        <div ref={setCollapsibleElement}>
                            <div className="collapse-wrap">
                                {props.children}
                            </div>
                        </div>

                    </div>
                )}
            </SlideToggle>
    );
}

export default React.memo(Card);