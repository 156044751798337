import React, { useMemo, Text } from 'react';
import { connect } from 'react-redux';

// import custom component
import OwlCarousel from '../features/owl-carousel';
import ProductNine from '../features/product/product-nine';
import { productSlider3, productSlider4 } from '../settings';
import { Link } from 'react-router-dom';


import { addToCart, toggleWishlist } from '../../actions';
import { getTopSellingProducts, getFeaturedProducts } from '../../services';

function BestColllection(props) {
    const { type, addToCart, toggleWishlist, products } = props;

    // if (type === "best")
    //     products = getTopSellingProducts(products);
    // else
    //     products = getFeaturedProducts(products);

    let xx = useMemo(() => products.map((type) => {
        let newArr = []


        if (props.searchq) {
            newArr = type.product.filter(item => {
                return item.name.toLowerCase().search(`${props.searchq.toLowerCase()}`) != -1
            })
            if (newArr.length > 0) {
                return (
                    <div className="bestseller-products bg-light pt-5 pb-5 mb-5" key={type.id}>
                        <div className="block">
                            <div className="block-wrapper ">
                                <div className="container">
                                    <div className="heading heading-flex">
                                        <div className="heading-left">
                                            <h2 className="title">{type.product_type}</h2>
                                        </div>

                                        <div className="heading-right">
                                            <Link to={`${process.env.PUBLIC_URL}/shop/products/3cols/${type.product_type}`} className="title-link">View more Products <i className="icon-long-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <OwlCarousel adClass="carousel-equal-height owl-simple" carouselOptions={type === "best" ? productSlider3 : productSlider4}>
                                        {type.product.map((item, index) => {
                                            if (item.name.toLowerCase().search(`${props.searchq.toLowerCase()}`) != -1)
                                                return <ProductNine product={item}
                                                    key={index}
                                                    onAddToCart={addToCart}
                                                    onToggleWishlist={toggleWishlist} />
                                        }
                                        ) || <Text>No product found for this search</Text>}
                                    </OwlCarousel>
                                    <div className=" mb-5"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }
        } else {
            return (
                <div className="bestseller-products bg-light pt-5 pb-5 mb-5" key={type.id}>
                    <div className="block">
                        <div className="block-wrapper ">
                            <div className="container">
                                <div className="heading heading-flex">
                                    <div className="heading-left">
                                        <h2 className="title">{type.product_type}</h2>
                                    </div>

                                    <div className="heading-right">
                                        <Link to={`${process.env.PUBLIC_URL}/shop/products/3cols/${type.product_type}`} className="title-link">View more Products <i className="icon-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                                <OwlCarousel adClass="carousel-equal-height owl-simple" carouselOptions={type === "best" ? productSlider3 : productSlider4}>
                                    {type.product.map((item, index) =>
                                        <ProductNine product={item}
                                            key={index}
                                            onAddToCart={addToCart}
                                            onToggleWishlist={toggleWishlist} />
                                    )}
                                </OwlCarousel>
                                <div className=" mb-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        newArr = []
    }), [products])


    return xx
}

function mapStateToProps(state, props) {
    return {
        products: state.data.products ? state.data.products : [],
    }
}

export default connect(mapStateToProps, { addToCart, toggleWishlist })(BestColllection);