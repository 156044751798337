import { findIndex } from '../utils';

/**
 * Get visible products
 * 
 * @param {Array} products 
 * @param {Object} param1
 * @return {Array} filtered products 
 */
export const getVisibleProducts = (products, { category }) => {
    return products.filter(item => {
        let catResult = false
        if (category && category.length > 0) {
            for (let i = 0; i < category.length; i++) {
                if (item.type.product_type === category[i] || (category[i] === 'All'))
                    catResult = true;
            }
        } else {
            catResult = true;
        }
        return catResult;
    })
}

/**
 * Get featured products
 * @param {Array} products 
 * @return {Array} featuredProducts
 */
export const getFeaturedProducts = (products) => {
    //return products.filter(item => true === item.featured);
    return products;

}

/**
 * Get sold products
 * @param {Array} products 
 * @return {Array} saleProducts
 */
export const getSaleProducts = (products) => {
    return products.filter(item => item.discount > 0);
}

/**
 * Get new products
 * @param {Array} products 
 * @return {Array} newProducts
 */
export const getNewProducts = (products) => {
    // return products.filter(item => item.category === "Digital Printing");
    return products;
}

/**
 * Get deal products
 * @param {Array} products 
 * @return {Array} dealProducts
 */
export const getDealProducts = (products, deal) => {
    return products.filter(item => item.category === "Digital Printing");
}
export const getTopRatingProducts = (products, deal) => {
    return products.filter(item => item.category === "Digital Printing");
}

/**
 * Get products which has top rating
 * @param {Array} products 
 * @return {Array} topRatingProducts
 */
// export const getTopRatingProducts = ( products ) => {
//     return products.filter( product => {
//         return product.ratings > 2
//     } ).sort( ( product1, product2 ) => {
//         return product2.ratings < product1.ratings ? -1 : 1;
//     } );
// }

/**
 * Get products which has top sale
 * @param {Array} products 
 * @return {Array} topSellingProducts
 */
// export const getTopSellingProducts = (products) => {
//     return products.filter(item => true === item.top);
// }
export const getTopSellingProducts = (products, deal) => {
    return products;
    //return products.filter(item => item.category === "Digital Printing");
}

/**
 * Get products filtered by category
 * @param {Array} products 
 * @param {String} category
 * @return {Array} filteredProducts
 */
export const getProductsByCategory = (products, category) => {
    return products;

    // if (category === "All") return products;

    // if (-1 !== category.indexOf('&')) {
    //     category = category.split(' & ');
    // }

    // return products.filter(item => {
    //     let result = false;

    //     if (Array.isArray(category)) {
    //         for (let i = 0; i < category.length; i++) {
    //             if (-1 !== item.category.indexOf(category[i])) {
    //                 result = true;
    //             }
    //         }
    //     } else {
    //         if (-1 !== item.category.indexOf(category))
    //             result = true;
    //     }
    //     return result;
    // });
}

/**
 * Get number of products filtered by category
 * @param {Array} products 
 * @param {String} category
 * @return {Integer} count of suitable products
 */
export const getCountByCategory = (products, category) => {
    if (category === "All") return products.length;
    // if (category === "Sale") return products.filter(item => item.discount > 0).length;
    return products.filter(item => -1 !== item.indexOf(category)).length;
}

/**
 * get total Price of products in cart.
 * @param {Array} cartItems 
 * @return {Float} totalPrice
 */
export const getCartTotal = cartItems => {
    let total = 0;
    for (let i = 0; i < cartItems.length; i++) {
        // total += parseInt(cartItems[i].qty, 10) * (cartItems[i].discount ? cartItems[i].salePrice : cartItems[i].price);
        total += cartItems[i].price;
    }
    return total;
}

/**
 * get number of products in cart
 * @param {Array} cartItems 
 * @return {Integer} numbers of cart items in cartlist
 */
export const getCartCount = cartItems => {
    let total = cartItems.length;

    for (let i = 0; i < cartItems.length; i++) {
        //total += parseInt(cartItems[i].qty, 10);
    }

    return total;
}

/**
 * Get number of products filtered by rating
 * @param {Array} products 
 * @param {String} category
 * @return {Integer} number of products filtered by rating
 */
export const getCountByRating = (products, rating) => {
    return products.filter(item => item.rating === rating).length;
}