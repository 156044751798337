import axios from 'axios';
import { API_URL } from '../utils/values';
import store from '../store'
import Axios from './axiosConfig'
import { logout } from '../actions';




//const API_URL = process.env.PUBLIC_URL;
//const state = store.getState();
// API to get products from mock server
export const getProducts = function () {
    return axios.get(process.env.REACT_APP_API_URL + '/products/api-list')
        .then(function (response) {

            return JSON.parse(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}


export const getCategories = function () {
    return axios.get(process.env.REACT_APP_API_URL + '/products/category-list')
        .then(function (response) {
            return JSON.parse(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const getUser = function (status) {
    let URL = process.env.REACT_APP_API_URL + '/customers/details';
    return Axios.get(URL, {
        headers: {
            authorization: "Bearer " + status
        }
    })
        .then(function (response) {
            console.log('token', response);
            return response.data;

        })
        .catch(function (error) {
            console.log(error);
        });
}



export const saveCustomerAndBasket = function (customer, basket) {
    return axios.post(process.env.REACT_APP_API_URL + '/order/savebasket', {
        customer: customer,
        basket: basket
    })

}
export const saveCustomerFromSocial = function (provider, token) {
    return axios.post(process.env.REACT_APP_API_URL + '/customers/social', {
        token,
        provider,
    })
}


export const login = function (email, password) {
    return axios.post(process.env.REACT_APP_API_URL + '/customers/login', {
        email: email,
        password: password
    })
        .then(function (response) {
            console.log('login res', response);
            if (response.status == 200)
                return response;
        })
        .catch(function (error) {
            return "Invalid Login"
        });
}