import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { isIEBrowser } from '../../../../../utils';

function DescOne(props) {
    const { product } = props;

    return (
        <Tabs selectedTabClassName="show" selectedTabPanelClassName="active show">
            <div className="product-details-tab">
                <TabList className="nav nav-pills justify-content-center">
                    <Tab className="nav-item">
                        <span className="nav-link"> Description</span>
                    </Tab>

                    <Tab className="nav-item">
                        <span className="nav-link"> Additional information</span>
                    </Tab>

                    <Tab className="nav-item">
                        <span className="nav-link">Delivery & Returns</span>
                    </Tab>

                    <Tab className="nav-item">
                        <span className="nav-link" >Reviews ({product.reviews ? product.reviews : 0})</span>
                    </Tab>
                </TabList>

                <div className="tab-content">
                    <TabPanel className="tab-pane">
                        <div className="product-desc-content" >
                            {product.product_desc}
                        </div>
                    </TabPanel>

                    <TabPanel className="tab-pane">
                        <div className="product-desc-content" >
                            {product.product_additional}

                        </div>
                    </TabPanel>

                    <TabPanel className="tab-pane">
                        <div className="product-desc-content" >
                            {product.delivery_info}

                        </div>
                    </TabPanel>

                    <TabPanel className="tab-pane">
                        <div className="reviews">
                            <h3>Reviews (2)</h3>
                            <div className="review">
                                <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}}>
                                    <div className="col-auto">
                                        <h4><Link to="#">Samanta J.</Link></h4>

                                        <div className="ratings-container">
                                            <div className="ratings">
                                                <div className="ratings-val" style={{ width: "80%" }}></div>
                                            </div>
                                        </div>
                                        <span className="review-date">6 days ago</span>
                                    </div>
                                    <div className="col">
                                        <h4>Good, perfect size</h4>

                                        <div className="review-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!</p>
                                        </div>

                                        <div className="review-action">
                                            <Link to="#"><i className="icon-thumbs-up"></i>Helpful (2)</Link>
                                            <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="review" >
                                <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}}>
                                    <div className="col-auto">
                                        <h4><Link to="#">John Doe</Link></h4>

                                        <div className="ratings-container">
                                            <div className="ratings">
                                                <div className="ratings-val" style={{ width: "100%" }}></div>
                                            </div>
                                        </div>

                                        <span className="review-date">5 days ago</span>
                                    </div>

                                    <div className="col">
                                        <h4>Very good</h4>

                                        <div className="review-content">
                                            <p>Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!</p>
                                        </div>

                                        <div className="review-action">
                                            <Link to="#"><i className="icon-thumbs-up"></i>Helpful (0)</Link>
                                            <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
        </Tabs>
    );
}

function mapStateToProps(state, props) {
    let prodx = [];
    state.data.products.map((type) => {
        if (type.product.length > 0)
            type.product.map((t2 => {
                prodx.push(t2)
            }))
    })
    let gg = prodx.filter(prod => {
        if (prod)
            return prod.slug == props.id
    })
    return {
        product: gg[0],
    }
}

export default connect(mapStateToProps)(DescOne);