import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { safeContent } from '../../utils';

export default function Banner(props) {
    const { img, height, adClass = "", contentAdClass = "", subtitle, title, btnText = "Order Now" } = props.banner;

    return (
        <div className={`banner ${adClass} banner-overlay`}>
            <Link to={props.act}>
                <div className="lazy-overlay bg-4" />

                <LazyLoadImage
                    src={process.env.PUBLIC_URL + '/' + img}
                    alt="Banner"
                    width={100}
                    height={height}
                    effect="blur"
                />
            </Link>

            <div className={`banner-content ${contentAdClass}`}>
                <h4 className="banner-subtitle text-white"><Link to={props.act}>{subtitle}</Link></h4>

                {
                    adClass === "banner-big" ?
                        <h2 className="banner-title text-white"><Link to={props.act} dangerouslySetInnerHTML={safeContent(title)} ></Link></h2>
                        : <h3 className="banner-title text-white"><Link to={props.act} dangerouslySetInnerHTML={safeContent(title)} ></Link></h3>
                }

                <Link to={props.act} className="btn btn-outline-white-3 banner-link">{btnText}<i className="icon-long-arrow-right"></i></Link>
            </div>
        </div>
    )
}