import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


function CategoryMenu(props) {
    return (
        <div className="dropdown category-dropdown">
            <Link to="#dropdown" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Browse Categories">
                Browse Categories
                </Link>

            <div className="dropdown-menu">
                <nav className="side-nav">
                    <ul className="menu-vertical sf-arrows sf-js-enabled" style={{ touchAction: 'pan-y' }}>
                        <li className="item-lead"><Link to="#">Unbeatable Quality Anytime</Link></li>

                        {
                            props.products.map((product) =>
                                <li key={product.id}><Link to={`${process.env.PUBLIC_URL}/shop/products/3cols/${product.product_type}`}>{product.product_type}</Link></li>)
                        }

                    </ul>
                </nav>
            </div>
        </div>
    );
}

//export default React.memo(CategoryMenu);

function mapStateToProps(state, props) {
    return {
        products: state.data.products ? state.data.products : []
    }
}

export default connect(mapStateToProps, {})(CategoryMenu);