import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, FormGroup, Progress, Row } from "reactstrap";
import { useDropzone } from 'react-dropzone';

import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk";

function WasabiUploader(props) {
   const formGroupStyle = {
      marginBottom: '5px',
      borderWidth: 1,
      borderStyle: 'dashed',
      height: 150,
      borderColor: "#A8A8A8"
   };

   const { onUploadCompleted, onFileDeleted } = props;

   const [uploadedFiles, setUploadedFiles] = useState([]);
   const removeUploaded = (key) => {
      setDropzoneSelectedFiles((initial) => initial.filter((i) => i.path !== key))
      onFileDeleted(key)
   }
   // Dropzone Config
   const [dropzoneSelectedFiles, setDropzoneSelectedFiles] = useState([]);
   const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
   const onFileDropped = useCallback(acceptedFiles => {
      setDropzoneSelectedFiles((initial) => [...initial, ...acceptedFiles]);
   }, [])

   const conf = {
      onDrop: onFileDropped,
      maxSize: 50000000, maxFiles: undefined !== props.maxFiles ? props.maxFiles : 1,
      accept: 'image/jpg, image/jpeg, image/png, application/pdf, image/vnd.adobe.photoshop, application/x-photoshop, application/photoshop, application/psd, image/psd, application/cdr,application/coreldraw,application/x-cdr,image/cdr,image/x-cdr, zz-application/zz-winassoc-cdr, application/postscript'
   };
   if (undefined !== props.acceptedMimes) {
      conf.accept = props.acceptedMimes;
   }

   const setupFileDZ = () => {
      const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone(conf);
      return { acceptedFiles, fileRejections, getRootProps, getInputProps };
   }
   const fileDz = setupFileDZ();

   // File size formatter
   const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   };

   // Formats the files into
   // Name - size - status
   const acceptedFileItems = (files) => {

      if (files.length < 1) return;
      return files.map(file => {

         const size = formatBytes(file.size, 2);
         const perc = undefined == file.status ? 0 : Math.abs(file.status.replace('%', ''));
         return (<li key={file.path}>
            <div style={{ marginTop: '5px' }}>
               {file.path} - {size} - {undefined == file.status ? "pending" : file.status}
            </div>
            {file.path !== undefined && <button type="button"
               className="close"
               aria-label="Close">
               <span style={{ marginLeft: 10, fontSize: 13 }} onClick={() => removeUploaded(file.path)}>Delete</span>
            </button>}
            {undefined == file.status ? null :
               <Progress className="progress-sm" color={perc == 100 ? 'success' : 'danger'} value={perc} style={{ marginTop: '5px' }}></Progress>}
         </li>);
      });
   }

   // UUID generator
   const uuid = () => {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = (dt + Math.random() * 16) % 16 | 0;
         dt = Math.floor(dt / 16);
         return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
   };

   // S3 Client setup
   const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_WASABI_ENDPOINT);
   const s3Client = new S3({
      region: process.env.REACT_APP_WASABI_REGION,
      accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
      secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
      endpoint: wasabiEndpoint
   });
   const folder = undefined !== props.uploadBase ? props.uploadBase : "creatives/";

   /**
    * [startUpload] - Main upload function
    * Called by the specific upload types
    */
   const startUpload = (file) => {
      if ('status' in file && file.status != "pending") return;
      if (undefined === file.name) return;

      const updatedFiles = dropzoneSelectedFiles.map((bf, i) => {
         if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
            bf.status = "pending";
         }
         return bf;
      });
      setDropzoneSelectedFiles(updatedFiles);

      // prepare the parameters
      const ext = file.name.split('.').pop();
      const key = folder + uuid() + '.' + ext;
      const params = { Key: key, Bucket: process.env.REACT_APP_WASABI_BUCKET, Body: file };

      // Setup the upload
      const upload = new AWS.S3.ManagedUpload({
         service: s3Client, // Use our customized service
         params: params
      });

      // As we get updates..
      upload.on('httpUploadProgress', ({ loaded, total }) => {
         const perc = Math.round(100 * loaded / total);

         const bFiles = dropzoneSelectedFiles.map((bf, i) => {
            if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
               bf.status = `${perc}%`;
            }
            return bf;
         });
         setDropzoneSelectedFiles(bFiles);
      });

      // Actual sending, with our callback included
      upload.send((err, data) => {

         if (err) {
         }
         else {
            const f = { ...data, name: file.name, size: file.size, path: file.path };
            const bFiles = dropzoneSelectedFiles.map((bf, i) => {
               if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
                  bf = f;
                  bf.status = "100%";
               }
               return bf;
            });
            setDropzoneSelectedFiles(bFiles);
         }
      });
   }

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         dropzoneSelectedFiles.forEach(file => {
            if (undefined === file.status) startUpload(file);
            if (undefined !== file.Location) {
               if (undefined !== onUploadCompleted && null !== onUploadCompleted) {
                  onUploadCompleted(file);
               }
            }
         });
      }

      return () => {
         dropzoneSelectedFiles.forEach(file => URL.revokeObjectURL(file.preview));
         isMounted = false;
      }
   }, [dropzoneSelectedFiles]);

   return (
      <React.Fragment>
         <FormGroup row className="mt-2" >
            <Col sm={12} className="container">
               <div {...fileDz.getRootProps({ className: 'dropzone text-center', style: { ...formGroupStyle, paddingTop: '40px', minHeight: '50px' } })}>
                  <input {...fileDz.getInputProps()} />
                  <p className="font-weight-bold p-3">
                     Drag 'n' drop to upload your design file. PNG, JPEG, PSD, AI, CDR, EPS etc. Max size  50mb
                  </p>
               </div>
               {dropzoneSelectedFiles.length > 0 && <div><h6 className="mt-2">Uploaded Files &nbsp; {"  "}</h6>
                  <ul className="list-unstyled">{acceptedFileItems(dropzoneSelectedFiles)}</ul></div>}
            </Col>
         </FormGroup>
      </React.Fragment>
   );
}

export default WasabiUploader;