import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';

import { rendererOne } from '../count-down';

import { findIndex } from '../../../utils';
import { IMG_URL } from '../../../utils/values';


function ProductSix(props) {
    const { product, type = "product", isWishlist, onAddToCart, showQuickView, onToggleWishlist, onAddToCompare } = props;

    // const addToCartHandler = () => {
    //     if ( 0 !== product.stock )
    //         onAddToCart( product, 1 );
    // }

    const addToCompareHandler = () => {
        onAddToCompare(product);
    }

    const quickViewHandler = () => {
        showQuickView(product.id);
    }

    const wishlistHandler = () => {
        if (isWishlist) {
            window.location = process.env.PUBLIC_URL + '/shop/wishlist';
        } else {
            onToggleWishlist(product, isWishlist);
        }
    }

    function toTop() {
        window.scroll({
            top: 0
        })
    }

    return (
        product ?
            <div className={`product product-5 text-center ${0 === product.stock ? 'product-disabled' : ''}`}>
                <figure className="product-media">
                    <Link to={`${process.env.PUBLIC_URL}/product/default/${product.slug}`} onClick={toTop}>
                        <img
                            alt="product"
                            src={(product && product.images.length > 0) && `${IMG_URL}/${product.images[0].filename}`}
                        />

                        {(product && product.images.length > 1) && product.images[1].filename ?
                            <span className="product-image-hover product-image">
                                <img
                                    alt="product"
                                    src={product.images.length > 1 && `${IMG_URL}/${product.images[1].filename}`}
                                />
                            </span>
                            : ''
                        }
                    </Link>


                    <div className="product-action-vertical">
                        <button
                            className={`btn-product-icon btn-wishlist ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                            onClick={wishlistHandler}
                            title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                        >
                            <span>{isWishlist ? "go to wishlist" : "add to wishlist"}</span>
                        </button>

                        {/* <button className="btn-product-icon btn-quickview" title="Quick view" onClick={quickViewHandler}>
                            <span>Quick view</span>
                        </button> */}

                        <button className="btn-product-icon btn-compare" title="Compare" onClick={addToCompareHandler}>
                            <span>Compare</span>
                        </button>
                    </div>

                    <div className="product-action">
                        <Link className="btn-product btn-cart" to={`${process.env.PUBLIC_URL}/product/default/${product.slug}`}>
                            <button  >
                                <span>Customise</span>
                            </button></Link>
                    </div>

                </figure>

                <div className="product-body product-action-inner">
                    <h3 className="product-title">
                        <Link to={`${process.env.PUBLIC_URL}/product/default/${product.slug}`} >{product.name}</Link>
                    </h3>

                    {


                        // <div className="product-price">${product.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    }
                </div>
            </div> : ''
    );
}

export const mapStateToProps = (state, ownprops) => {
    return {
        isWishlist: (findIndex(state.wishlist.list, item => item.id === ownprops.product.id) !== -1) ? true : false
    };
}

export default connect(mapStateToProps)(ProductSix);