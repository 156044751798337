import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { mobileMenu } from '../../../utils';
import { connect } from 'react-redux';


function MobileMainNav(props) {
    const [path, setPath] = useState("");

    useEffect(() => {
        setPath(window.location.href);
    })

    React.useEffect(() => {
        mobileMenu();
    })

    return (
        <nav className="mobile-nav">
            <ul className="mobile-menu">
                <li>
                    <Link to={`${process.env.PUBLIC_URL}`}>
                        Home
                    </Link>
                </li>
                <li className={path.indexOf("categories") > -1 ? 'active' : ''}>
                    <Link to={`${process.env.PUBLIC_URL}/product/list`} className="sf-with-ulOLD">Shop</Link>
                </li>
                <li className={path.indexOf("product/") > -1 ? 'active' : ''}>
                    <Link to={`${process.env.PUBLIC_URL}/product/list`} className="sf-with-ulOLD">Product</Link>
                </li>
                <li className={path.indexOf("blog/") > -1 ? 'active' : ''}>
                    <Link to={`${process.env.PUBLIC_URL}/blog/grid/2cols`} className="sf-with-ulOLD">Blog</Link>
                </li>
                <li className={path.indexOf("element") > -1 ? 'active' : ''}>
                    <Link to={`${process.env.PUBLIC_URL}/elements`} className="sf-with-ulOLD">CONTACT US</Link>
                </li>


            </ul>
        </nav>
    );
}

function mapStateToProps(state, props) {
    return {
        products: state.data.products ? state.data.products : []
    }
}

export default connect(mapStateToProps, {})(MobileMainNav);

