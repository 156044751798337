import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';

import { PersistGate } from 'redux-persist/integration/react';

// import store
import store, { persistor } from './store';

// import action
import { getAllProducts, getAllCategories, refreshStore } from './actions';

// import routes
import AppRoute from './routes';

// import Utils
import { initFunctions } from './utils';

import LoadingOverlay from './components/features/loading-overlay';

export function Root() {
    initFunctions();
    store.dispatch(getAllProducts());
    //store.dispatch(getAllCategories());

    useEffect(() => {
        if (store.getState().modal.current !== 20) {
            store.dispatch(refreshStore(20));
        }
    }, [])

    return (
        <Provider store={store} >
            <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
                <BrowserRouter basename={'/'} >
                    <AppRoute />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));