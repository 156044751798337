import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';


// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import ProductNine from '../../features/product/product-nine';
import { addToCart, toggleWishlist } from '../../../actions';






function Listing(props) {

    const { products, addToCart, toggleWishlist } = props;

    return (
        <>
            <Helmet>
                <title>JayKay Global | Products</title>
            </Helmet>

            <h1 className="d-none">JayKay Global - Product </h1>

            <div className="main">

                <PageHeader title="Products" subTitle="" />
                <Breadcrumb title="Products" parent1={["Home", "/"]} />

                <div className="page-content">


                    <div className="container">
                        <div className="row ">
                            {products[0].product.map((item, index) =>
                                <ProductNine product={item}
                                    key={index}
                                    onAddToCart={addToCart}
                                    onToggleWishlist={toggleWishlist} />
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state, props) {
    let prodx = [];
    let slug = props.match.params.slug;
    let products = state.data.products.filter((type) => type.slug == slug)

    return {
        products: products ? products : []
    }
}

export default connect(mapStateToProps, { addToCart, toggleWishlist })(Listing);