import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { closeModal, updateUser, updateOrder } from '../../../actions';
import { login } from '../../../api'
import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialTwitter,
    IResolveParams,
    TypeCrossFunction
} from 'reactjs-social-login'

import { saveCustomerFromSocial } from '../../../api/index'



const customStyles = {
    content: {
        top: '50%',
        transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '10000'
    }
};

Modal.setAppElement('#root');

function LoginModal(props) {
    const { showModal, modal } = props;
    let timer;

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState<any>()
    const googleRef = useRef<TypeCrossFunction>(null!)
    const facebookRef = useRef<TypeCrossFunction>(null!)
    const twitterRef = useRef<TypeCrossFunction>(null!)

    const onLoginStart = useCallback(() => {
        //alert('login start')
    }, [])

    const onLogoutFailure = useCallback(() => {
        // alert('logout fail')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        //alert('logout success')
    }, [])

    const onLogout = useCallback(() => {
        switch (provider) {
            case 'facebook':
                facebookRef.current && facebookRef.current.onLogout()
                break
            case 'google':
                googleRef.current && googleRef.current.onLogout()
                break
            case 'twitter':
                twitterRef.current && twitterRef.current.onLogout()
                break
            default:
                break
        }
    }, [provider])



    let history = useHistory();

    const [errorState, setErrorState] = useState();

    const onChangeHandler = (e) => {
        const value = e.target.value;
        setLoginData({
            ...loginData,
            [e.target.name]: value,
        })
    }

    const handleLogin = (event) => {
        //setLoaded(false);
        event.preventDefault()
        login(loginData.email, loginData.password)
            .then((result) => {
                if (result && result.status == 200) {
                    let customer = result.data.customer;
                    let orders = result.data.order;
                    let access_token = result.data.access_token;
                    customer = { ...customer, access_token }
                    async function f() {
                        props.updateUser(customer);
                        props.updateOrder(orders);
                    }
                    f().then(() => {
                        props.closeModal('login')
                        history.push('/shop/dashboard')
                    })

                }
                else {
                    setErrorState("Invalid Login details");
                }
            }).then(() => {
                // setLoaded(true);
                //window.location.href = '/shop/dashboard';
            });

    }
    const handleSubmit = (provider, token) => {
        saveCustomerFromSocial(provider, token)
            .then(function (response) {
                const result = response.data;
                let customer = result.customer;
                let orders = result.orders;
                let access_token = result.access_token;
                // if (access_token) {
                //     //setLoaded(true);
                //     window.location.href = "/shop/dashboard/" + access_token;
                // }
                customer = { ...customer, access_token }
                props.updateUser(customer);
                props.updateOrder(orders);
            }).then(() => {
                closeModal();
                window.location.href = "/shop/dashboard"
            })
            .catch(function (error) {
                setErrorState('Your identity could not be verified, please try again later')
                //setError("An error occurred. Please check your inputs and try again");
                // return (dispatch, state) => {
                //     dispatch(logout());
                // }
            });
    }



    function closeModal() {
        document.getElementById("login-modal").classList.remove("ReactModal__Content--after-open");

        timer = setTimeout(() => {
            props.closeModal('login');
        }, 200);
    }

    useEffect(() => {
        return () => {
            if (timer) clearTimeout(timer);
        }
    })

    return (
        <Modal
            isOpen={showModal && 'login' === modal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Login Modal"
            className="modal-dialog modal-dialog-centered"
            id="login-modal" >
            <div className="modal-content">
                <div className="modal-body">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                    <div className="form-box">
                        <div className="form-tab">
                            <Tabs selectedTabClassName="show" defaultIndex={0}>
                                <TabList className="nav nav-pills nav-fill">
                                    <Tab className="nav-item">
                                        <span className="nav-link">Sign In</span>
                                    </Tab>

                                    {/* <Tab className="nav-item">
                                        <span className="nav-link">Register</span>
                                    </Tab> */}
                                </TabList>

                                <div className="tab-content">
                                    <TabPanel style={{ paddingTop: "2rem" }}>
                                        <div>
                                            <form action="#" onSubmit={handleLogin}>
                                                {
                                                    errorState &&
                                                    <div className="alert alert-danger alert-dismissible fade show m-1" role="alert">
                                                        {errorState}
                                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                    </div>
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="singin-email-2">Email address *</label>
                                                    <input type="email" className="form-control" id="singin-email-2" name="email" value={loginData.email} required onChange={onChangeHandler} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="singin-password-2">Password *</label>
                                                    <input type="password" className="form-control" id="password" name="password" value={loginData.password} required onChange={onChangeHandler} />
                                                </div>

                                                <div className="form-footer">
                                                    <button type="submit" className="btn btn-outline-primary-2">
                                                        <span>LOG IN</span>
                                                        <i className="icon-long-arrow-right"></i>
                                                    </button>

                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="signin-remember-2" />
                                                        <label className="custom-control-label" htmlFor="signin-remember-2">Remember Me</label>
                                                    </div>

                                                    {/* <Link to="#" className="forgot-link">Forgot Your Password?</Link> */}
                                                </div>
                                            </form>
                                            <div className="form-choice">
                                                <p className="text-center">or sign in with</p>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <LoginSocialGoogle
                                                            ref={googleRef}
                                                            client_id={process.env.REACT_APP_GG_APP_ID || ''}
                                                            onLogoutFailure={onLogoutFailure}
                                                            onLoginStart={onLoginStart}
                                                            onLogoutSuccess={onLogoutSuccess}
                                                            onResolve={({ provider, data }: IResolveParams) => {
                                                                handleSubmit('google', data.access_token)
                                                            }}
                                                            onReject={(err) => {
                                                                setErrorState('An error occured when connecting to Google')
                                                            }}
                                                            className="btn btn-login btn-g"
                                                        >
                                                            <i className="icon-google"></i>
                                                             Google
                                                    </LoginSocialGoogle>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <LoginSocialFacebook
                                                            ref={facebookRef}
                                                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                                                            client_secret={process.env.REACT_APP_FB_APP_SECRET || ''}
                                                            onLoginStart={onLoginStart}
                                                            onLogoutSuccess={onLogoutSuccess}
                                                            scope={'email,public_profile'}
                                                            onResolve={({ provider, data }: IResolveParams) => {
                                                                handleSubmit('facebook', data.accessToken)
                                                            }}
                                                            onReject={(err) => {
                                                                setErrorState('An error occured when connecting to Facebook')
                                                            }}
                                                            className="btn btn-login btn-f"
                                                        >
                                                            <i className="icon-facebook-f"></i>
                                                             Facebook
                                                    </LoginSocialFacebook>
                                                    </div>
                                                    {/* <div className="col-sm-4">
                                                        <LoginSocialTwitter
                                                            ref={twitterRef}
                                                            client_id={process.env.REACT_APP_TWITTER_API_KEY || ''}
                                                            client_secret={process.env.REACT_APP_TWITTER_APP_SECRET || ''}
                                                            redirect_uri={'https://store.jaykayglobal.com'}
                                                            onLoginStart={onLoginStart}
                                                            onLogoutSuccess={onLogoutSuccess}
                                                            onResolve={({ provider, data }: IResolveParams) => {
                                                                setProvider(provider)
                                                                setProfile(data)
                                                                console.log('twit', data)
                                                            }}
                                                            onReject={(err: any) => {
                                                                console.log(err)
                                                            }}
                                                            className="btn btn-login btn-f"
                                                        >
                                                            <i className="icon-twitter"></i>
                                                             Twitter
                                                    </LoginSocialTwitter>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {/* <TabPanel>
                                        <form action="#">
                                            <div className="form-group">
                                                <label htmlFor="register-email-2">Your email address *</label>
                                                <input type="email" className="form-control" id="register-email-2" name="register-email" required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="register-password-2">Password *</label>
                                                <input type="password" className="form-control" id="register-password-2" name="register-password" required />
                                            </div>

                                            <div className="form-footer">
                                                <button type="submit" className="btn btn-outline-primary-2">
                                                    <span>SIGN UP</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>

                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="register-policy-2" required />
                                                    <label className="custom-control-label" htmlFor="register-policy-2">I agree to the <Link to="#">privacy policy</Link> *</label>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="form-choice">
                                            <p className="text-center">or sign in with</p>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <Link to="#" className="btn btn-login btn-g">
                                                        <i className="icon-google"></i>
                                                        Login With Google
                                                    </Link>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Link to="#" className="btn btn-login  btn-f">
                                                        <i className="icon-facebook-f"></i>
                                                        Login With Facebook
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel> */}
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        showModal: state.modal.showModal,
        modal: state.modal.modal,
        state: state,
    }
}

export default connect(mapStateToProps, { closeModal, updateUser, updateOrder })(LoginModal);
