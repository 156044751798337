import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Magnifier } from "react-image-magnifiers";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { quantityInputs } from '../../../../../utils';


function MediaOne(props) {
    const { product, adClass = "product-gallery-vertical" } = props;


    if (!product) {
        window.location = process.env.PUBLIC_URL + "pages/404";
    }

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setStatus] = useState(false);

    const bigImages = product.images;

    useEffect(() => {
        quantityInputs();
    }, [])

    function openLightBox() {
        let index = parseInt(document.querySelector(".product-main-image").getAttribute("index"));

        if (!index) {
            index = 0;
        }
        setStatus(true);
        setPhotoIndex(index);
    }

    function closeLightBox() {
        setStatus(false);
    }

    const setNextHandler = () => {
        setPhotoIndex(photoIndex => (photoIndex + 1) % bigImages.length);
    }

    const setPrevHandler = () => {
        setPhotoIndex(photoIndex => (photoIndex + bigImages.length - 1) % bigImages.length);
    }

    return (
        <>
            <div className={`product-gallery ${adClass}`}>
                <div className="row m-0">
                    <figure className="product-main-image" index="0">
                        {product.new ? <span className="product-label label-new">New</span> : ''}

                        {product.top ? <span className="product-label label-top">Top</span> : ''}

                        {product.discount ? <span className="product-label label-sale">{product.discount}% off</span> : ''}

                        {(0 === product.stock) ? <span className="product-label label-out">Out of Stock</span> : ''}


                        {product && product.images.length > 0 &&
                            <Magnifier
                                imageSrc={process.env.REACT_APP_IMG_URL + '/' + product.images[0].filename}
                                imageAlt="Example"
                                largeImageSrc={process.env.REACT_APP_IMG_URL + '/' + product.images[0].filename} // Optional
                                dragToMove={false}
                                mouseActivation="hover"
                                cursorStyleActive="crosshair"
                                id="product-zoom"
                            />
                        }

                        <button id="btn-product-gallery" className="btn-product-gallery" onClick={openLightBox}>
                            <i className="icon-arrows"></i>
                        </button>
                    </figure>

                    <div id="product-zoom-gallery" className="product-image-gallery">
                        {
                            product.images.map((item, index) =>
                                <button className={`product-gallery-item ${0 === index ? 'active' : ''}`} to="#" data-image={process.env.REACT_APP_IMG_URL + '/' + item.filename} data-zoom-image=
                                    {process.env.REACT_APP_IMG_URL + '/' + item.filename}
                                    key={product.id + '-' + index}>
                                    <img src={process.env.REACT_APP_IMG_URL + '/' + item.filename} alt="product back" />
                                </button>
                            )
                        }
                    </div>

                </div>
            </div>

            {
                isOpen ?
                    <Lightbox
                        mainSrc={process.env.REACT_APP_IMG_URL + '/' + bigImages[photoIndex].filename}
                        nextSrc={bigImages.length >= (photoIndex + 1) && (process.env.REACT_APP_IMG_URL + '/' + bigImages[(photoIndex + 1) % bigImages.length].filename)}
                        prevSrc={(photoIndex - 1) >= 0 && (process.env.REACT_APP_IMG_URL + '/' + bigImages[(photoIndex - 1) % bigImages.length].filename)}
                        onCloseRequest={closeLightBox}
                        onMovePrevRequest={setNextHandler}
                        onMoveNextRequest={setPrevHandler}
                    />
                    : ''
            }
        </>
    )
};

function mapStateToProps(state, props) {
    let prodx = [];
    state.data.products.map((type) => {
        if (type.product.length > 0)
            type.product.map((t2 => {
                prodx.push(t2)
            }))
    })
    let gg = prodx.filter(prod => {
        if (prod)
            return prod.slug == props.id
    })
    return {
        product: gg[0]
    }

}

export default connect(mapStateToProps)(MediaOne);