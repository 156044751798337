import * as axios from "axios";
import store from '../store'
import { API_URL } from '../utils/values';
import LandingOverlay from '../components/common/loading-overlay';


var instance = axios.create();
instance.defaults.baseURL = process.env.REACT_APP_API_URL;
const state = store.getState();

instance.defaults.timeout = 20000;

//instance.defaults.headers.common["basic-token"] =
// "qFP932uNSyJJu1rtbYZY1WMKhD37oMg9+njyJnONeZg";
// instance.defaults.headers.common["token"] =
//"KdwFn1o22EgbYlEU$UJJJ40km6Gigwv6A^@058_0";
if (state.user.user && state.user.user.access_token)
    instance.defaults.headers.common['Authorization'] =
        "Bearer " + state.user.user.access_token;

instance.interceptors.request.use(
    (config) => {
        // trigger 'loading=true' event here
        ///LandingOverlay(false)
        return config;
    },
    (error) => {
        // trigger 'loading=false' event here
        //LandingOverlay(true)
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // trigger 'loading=false' event here
        //LandingOverlay(true)
        return response;
    },
    (error) => {
        // trigger 'loading=false' event here
        //LandingOverlay(true)
        if (
            error.response.status == 401 ||
            (error.response.status == 500 &&
                error.response.data &&
                error.response.data.message == "Token has expired")
        ) {
            // store.dispatch(logoutUser());
            // NavigationService.navigate("auth");
        }
        return Promise.reject(error);
    }
);

export { instance as default };
