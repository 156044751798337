import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import Custom Components
import NewsletterModal from '../features/modal/newsletter-modal';
import Service from '../features/service';

import Banner from './banner';
import BestCollection from './best-collection';
//import NewCollection from './new-collection';
import BlogSection from './blog-section';
import CTA from './cta-section';
import data from '../../mock_data/data';
import style from './style.scss';

function Search(props) {
    useEffect(() => {
        document.getElementById("menu-home").classList.add("active");
        style.use();
        return (() => {
            document.getElementById("menu-home").classList.remove("active");
            style.unuse();
        })
    }, [])


    let productsLayout = () => {

        return <BestCollection type="best" searchq={searchText} />

    }

    const [searchText, setSearchText] = useState(null);

    useEffect(() => {
        const sp = new URLSearchParams(props.location.search);
        setSearchText(sp.get("q"));
    }, [props.location.search])




    return (
        <>
            <Helmet>
                <title>JayKay Global- Digital Printing & Plate Making</title>
            </Helmet>

            <h1 className="d-none">JayKay Global- Digital Printing & Plate Making</h1>

            <div className="main home-page">
                <div className=" container mt-4 mb-4">
                    <h4>Search result for "{searchText}"</h4>
                </div>

                {productsLayout()}



                <div className="mb-5"></div>


                <div className="mb-5"></div>

                {/* <BlogSection /> */}
                {/* <CTA /> */}
            </div>

            {/* <NewsletterModal /> */}
        </>
    )
}

export default Search;