import { SIGN_IN, ORDER_LIST, LOGOUT } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = {
    user: [],
    order: []
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state,
                user: action.user
            };

        case LOGOUT:
            return {
                ...state,
                user: {
                    ...initialState
                }
            };

        case ORDER_LIST:
            return {
                ...state,
                order: action.order
            };

        default:
            return state;
    }
};
const persistConfig = {
    keyPrefix: "molla-",
    key: "user",
    storage
}

export default persistReducer(persistConfig, userReducer);