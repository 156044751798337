import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CartAction from '../../../../features/accordion/cartActions';
import CartActionTwo from '../../../../features/accordion/cartActionsTwo';
import Accordion from '../../../../features/accordion/accordion';


import { addToCart, toggleWishlist } from '../../../../../actions';

import { quantityInputs, isIEBrowser, isEdgeBrowser, findIndex, countTo } from '../../../../../utils';

function ProductDetailOne(props) {
    const { product, isWishlist, addToCart, toggleWishlist } = props;

    const [selectedBaseline, setSelectedBaseline] = useState({})
    const [quantity, setQuantity] = useState(10)
    const [price, setPrice] = useState('...')
    const [defaultSelectValues, setDefaultSelectValues] = useState({});
    const [control, setControl] = useState(true)


    let vcount = 0


    useEffect(() => {
        quantityInputs();
        setDefaultBaseline()
    }, [control, product]);

    // const handleChange = (event) => {
    //     const obj = event.target.value.split("__");
    //     setSelectedBaseline(intial => {
    //         return {
    //             ...intial,
    //             [obj[0]]: {
    //                 baselineID: parseInt(obj[0]),
    //                 baselineName: obj[1],
    //                 bvalueID: parseInt(obj[2]),
    //                 bvalueName: obj[3],
    //                 bValueCost: parseFloat(obj[4]),
    //             }
    //         }

    //     })
    //     setControl(!control)

    // }

    const isObjEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    // const calcPrice = () => {
    //     let price = 0
    //     Object.values(selectedBaseline).forEach(val => {
    //         price += val.bValueCost
    //     });
    //     setPrice(price * quantity)
    // }
    // const handleQtyChange = (event) => {
    //     const qty = parseInt(event.target.value);
    //     setQuantity(qty);
    //     setControl(!control);
    // }

    let setDefaultBaseline = () => {
        if (isObjEmpty(selectedBaseline)) {
            let sortedBvalue, bases = {}, defaultValues = {};
            product.baseline.map(item => {
                sortedBvalue = item.bvalue.sort(function (a, b) {
                    return a.cost - b.cost
                });
                bases[item.id] = {
                    baselineID: parseInt(item.id),
                    baselineName: item.name,
                    bvalueID: parseInt(sortedBvalue[0].id),
                    bvalueName: sortedBvalue[0].name,
                    bValueCost: parseFloat(sortedBvalue[0].cost),
                };
                defaultValues[item.id] = item.id + '__' + item.name + '__' + parseInt(sortedBvalue[0].id) + '__' + sortedBvalue[0].name + '__' + parseFloat(sortedBvalue[0].cost);
                return 0;
            })

            setSelectedBaseline(bases);
            setDefaultSelectValues(defaultSelectValues);
            setControl(!control);
        }
        //calcPrice()
    }

    const wishlistHandler = () => {
        if (isWishlist) {
            window.location = process.env.PUBLIC_URL + '/shop/wishlist';
        } else {
            toggleWishlist(product, isWishlist);
        }
    }

    const titleTransform = (v) => {
        let xx = {}
        const b = Object.fromEntries(
            Object.entries(selectedBaseline).filter(([key, value]) => {
                if (key == v) xx = value
                return key == v
            }))
        return xx.baselineName;
    }

    let v = JSON.parse(product.variation)

    return (
        <div className={"product-details"}>
            <h1 className="product-title">{product.name}</h1>

            <div className="ratings-container">
                {/* <div className="ratings">
                    <div className="ratings-val" style={{ width: product.ratings * 20 + '%' }}></div>
                </div>
                <Link className="ratings-text" to="#product-review-link" id="review-link">( {product.reviews} Reviews )</Link> */}
            </div>


            {/* <div className="product-price">
                <span className="new-price">GHS {(isNaN(price) ? 0 : price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
            </div> */}
            <div className="product-price">
                <span className="product-cat mt-3 mb-3">
                    {product.product_desc}
                </span>
            </div>


            {/* 
                
                < product.discount ?
                    <div className="product-price">
                        <span className="new-price">${ product.salePrice.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                        <span className="old-price">${ product.price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                    </div> :
                    <div className="product-price">${ product.price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</div> }*/
            }

            <div className="product-content">
                {/* <p>{product.type.name}</p> */}
            </div>


            {/* {
                product.baseline.map(item => {
                    return <div className="details-filter-row details-row-size">
                        <div className="col-3">
                            <label htmlFor="size">{item.name}:</label>
                        </div>
                        <div className="select-custom">
                            <select name="size" id="size" className="form-control" defaultValue={defaultSelectValues[item.id]}
                                onChange={handleChange}


                            >
                                {item.bvalue.map(bv =>
                                    <option value={item.id + '__' + item.name + '__' + bv.id + '__' + bv.name + '__' + bv.cost}>{bv.name}</option>
                                )}


                            </select>
                        </div>
                    </div>
                })
            } */}

            <Accordion adClass="accordion-arrow product-details-accordion">
                {
                    v.map((varia) => {
                        vcount++
                        if (product.type.pricing_model == 1)
                            return < CartAction vcount={vcount} addToCart={addToCart} product={product} varia={varia} selectedBaseline={selectedBaseline} ></CartAction>
                        else if (product.type.pricing_model == 2)
                            return < CartActionTwo vcount={vcount} addToCart={addToCart} product={product} varia={varia} selectedBaseline={selectedBaseline} ></CartActionTwo>
                    })
                }
            </Accordion>

            <div className="product-details-action">



                {isIEBrowser() || isEdgeBrowser() ?
                    <div className="details-action-wrapper IE-detail-action-wrapper">
                        <button
                            className={`btn-product btn-wishlist pl-0 pr-0 ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                            onClick={wishlistHandler}
                            title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                        >
                            <span>{isWishlist ? "go to wishlist" : "add to wishlist"}</span>
                        </button>
                    </div> :

                    <div className="details-action-wrapper">
                        <button
                            className={`btn-product btn-wishlist pl-0 pr-0 ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}`}
                            onClick={wishlistHandler}
                            title={isWishlist ? "Go to wishlist" : "Add to wishlist"}
                        >
                            <span>{isWishlist ? "Go to Wishlist" : "Add to Wishlist"}</span>
                        </button>
                    </div>
                }
            </div>


            <div className="mt-5"></div>

            <div className="product-details-footer">
                <div className="product-cat">
                    <span>Category: </span>
                    <span className="mr-0">
                        <Link to="#">{product.type.product_type}</Link>
                    </span>
                    {/* 
                    {product.category.map((cat, index) => (
                        <span key={index} className="mr-0">
                            <Link to="#">{cat}</Link>
                            { index < product.category.length - 1 ? ', ' : ''}
                        </span>
                    ))} */}
                </div>

                <div className="social-icons social-icons-sm">
                    <span className="social-label">Share:</span>
                    <Link to="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></Link>
                    <Link to="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></Link>
                    <Link to="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></Link>
                    <Link to="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></Link>
                </div>
            </div>

            {props.children}
        </div >
    )
}

function mapStateToProps(state, props) {
    let prodx = [];
    state.data.products.map((type) => {
        if (type.product.length > 0)
            type.product.map((t2 => {
                prodx.push(t2)
            }))
    })
    let gg = prodx.filter(prod => {
        if (prod)
            return prod.slug == props.id
    })
    return {
        product: gg[0],
        isWishlist: (findIndex(state.wishlist.list, item => item.id === parseInt(props.id)) !== -1) ? true : false
    }
}

export default connect(mapStateToProps, { addToCart, toggleWishlist })(ProductDetailOne);