import React, { useEffect, useState, useCallback } from 'react';

import Card from './card';
import { Link } from 'react-router-dom';

import { quantityInputs, isIEBrowser, isEdgeBrowser, findIndex, countTo } from '../../../utils';
import WasabiUploader from '../../common/wasabi-uploader';
import { toast } from 'react-toastify';



function CartAction(props) {


    const { addToCart, product, varia, vcount, selectedBaseline } = props;



    const [quantity, setQuantity] = useState(1)
    const [price, setPrice] = useState('...')
    const [control, setControl] = useState(true)
    const [formData, setFormData] = useState([])

    useEffect(() => {
        updatedPrice()
    }, [control]);

    const onFileUploadCompleted = useCallback((sentFile) => {
        if (undefined !== sentFile.Location) {
            setFormData(initial => {
                let found = false
                const files = initial.map((f) => {
                    if (f.key === sentFile.key) {
                        f = sentFile
                        found = true
                    }
                    return f
                })
                if (!found)
                    return [...initial, sentFile]
                else return files
            })
        }
        setControl(!control);
    })
    const onFileDeleted = useCallback((key) => {
        if (undefined !== key) {
            setFormData((initial) => initial.filter((i) => i.path !== key))
        }
        setControl(!control);
    })


    const updatedPrice = (e = false) => {

        if (document.querySelector('#basicQty' + vcount)) {
            let basicQty = document.querySelector('#basicQty' + vcount).value;
            let basicPrice = document.querySelector('#basicPrice' + vcount).value;
            let qty = 1
            if (e) {
                setQuantity(e.target.value)
                qty = e.target.value
            }
            else {
                qty = document.querySelector('#qty' + vcount).value;
                setQuantity(qty)
            }

            let cost = (qty / parseInt(basicQty)) * parseFloat(basicPrice)
            setPrice(cost);

        }
    }

    let options = (minQty) => {
        let options = []
        for (let i = 1; i <= 100; i++) {
            options.push(<option value={i * minQty}>{i * minQty}</option>);
        }
        return options
    }


    const titleTransform = (v) => {
        let xx = {}
        const b = Object.fromEntries(
            Object.entries(selectedBaseline).filter(([key, value]) => {
                if (key == v) xx = value
                return key == v
            }))
        return xx.baselineName;
    }


    const handleQtyChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setControl(!control);
    }

    const addToCartHandler = (v, variation) => {
        let basicQty = document.querySelector('#basicQty' + vcount).value;
        let basicPrice = document.querySelector('#basicPrice' + vcount).value;
        let cost = (quantity / parseInt(basicQty)) * parseFloat(basicPrice)
        let productToCart = {
            ...product,
            price: cost,
            variation: varia,
            qty: parseInt(quantity),
            assets: formData.length > 0 ? formData : []
        }
        if (formData && formData.length > 0)
            addToCart(productToCart, quantity);
        else
            toast.success("Please upload your design to continue")
    }

    return (
        <Card key={vcount} title={varia.name + " - GHS " + price} expanded={vcount == 1 && true} adClass="card-box card-sm">
            <div className="product-desc-content">
                {Object.keys(varia).map(key => {
                    if (key != 'qty' && key != 'name' && key != 'price')
                        return <div className="row mb-1"><div className="col-4"><strong>{titleTransform(key)}:</strong></div> <div className="col-4">{varia[key].name}</div></div>
                })
                }                        </div>

            <WasabiUploader maxFiles={5} onUploadCompleted={onFileUploadCompleted} onFileDeleted={onFileDeleted} />

            <div className="product-details-action mt-3">
                <div className="details-filter-row details-row-size">
                    <div className="product-details-quantity mt-1 mr-3">
                        <input type="hidden" id={"basicQty" + vcount} value={varia.qty} />
                        <input type="hidden" id={"basicPrice" + vcount} value={varia.price} />
                        {varia.qty == 1 ? <input className="form-control" type="number" value={quantity} id={'qty' + vcount} min={1} onChange={() => updatedPrice()} /> : <select className="form-control" id={'qty' + vcount} onChange={() => updatedPrice()}>
                            {options(varia.qty)}
                        </select>}

                    </div>
                </div>

                {isIEBrowser() || isEdgeBrowser() ?
                    <button className="btn-product btn-cart ml-3" onClick={() => addToCartHandler(varia)} style={{ minHeight: "4rem" }}><span>add to cart</span></button>
                    :
                    <button className="btn-product btn-cart ml-2" onClick={() => addToCartHandler(varia)}><span>add to cart</span></button>
                }

            </div>

        </Card>
    );
}

export default React.memo(CartAction);